import React, { useContext, useEffect, useState } from "react";
import css from "../css/Mywallet.module.css";
import Rightcontainer from "../Components/Rightcontainer";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import socket from "../Components/socket";
import { AiOutlineHistory, AiOutlinePlus } from "react-icons/ai";
import { BsWallet2 } from "react-icons/bs";
import io from "../Components/socket";
import { LanguageContext } from "../Components/LanguageContext";
import { useTheme } from "../../ThemeContext";
import Swal from "sweetalert2";



const Mywallet = ({user}) => {
  // const [user, setUser] = useState();
    // BASE URL
 
    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    if (nodeMode === "development") {
  
      var baseUrl = beckendLocalApiUrl;
    } else {
      baseUrl = beckendLiveApiUrl;
  }

  const access_token = localStorage.getItem("token");
  const addsetting = localStorage.getItem("sitSetting");

  const addCaseSetting = JSON.parse(addsetting);
  const udata = localStorage.getItem("udata");
  const profileData = JSON.parse(udata);
  const time = new Date()
  const [transaction, setTransaction] = useState([])
//   const walletUpdate =()=>{
//  io.emit("getprofile", access_token);
//   io.on("setProfile", (data) => {
//     setUser(data);
//   });
//   }

//   useEffect(()=>{
//     walletUpdate()
//   },[]);

// language 


const Allgames = async (id) => {


  const access_token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${access_token}`,
  };
  await axios
    .get(
      baseUrl+`temp/deposite/${id}?page=${1}&_limit=${10}`,
      { headers }
    )
    .then((res) => {
      setTransaction(res.data.ress[0]?.status)
      {
        // console.log(res.data.ress);
      }

      
    })
    .catch((e) => {});
};

const translations = {
  hi: {
    wallet: 'वॉलेट',
    history: 'इतिहास देखे | ',
    amount: 'पैसे जोड़ें | ',
    withdraw: 'पैसे निकाले  | ',
    referPerson: 'रेफ़रल | ',
  },
  en: {
    wallet: 'My Wallet',
    history: 'Order History',
    amount: 'Add Cash',
    withdraw: 'Withdraw',
    referPerson: 'Referral',
  },
};

const { language } = useContext(LanguageContext);


 // change theme 
 const theme  = localStorage.getItem('theme')

 const styles = {
   backgroundColor: theme === 'light' ? '#fff' : 'black',
   color: theme === 'light' ? 'black' : '#fff',
 };


 const sweetAlert = () => {
  Swal.fire({
    text: "Your Withdrawal request already submited",
    icon: "error"
  });
 }

 useEffect(() => {
  Allgames(profileData._id)
 },[])
 

  return (
    <>
      <div className="leftContainer mb_space" style={{...styles, height:"100vh"}}>
        <div className="pt-5 mt-5  Orher_page_main_section">
          <div className="main_heading_with_btn">
            <h2 className="profile_headings" style={styles}>{translations[language].wallet}</h2>
            <Link className="profile_wallet" to="/history">
              <AiOutlineHistory className="icons" /> {translations[language].history}
            </Link>
          </div>
          {/* <div style={{ width: "fit-content", marginTop: "10px" }}>
            <a
              href="https://youtu.be/-OocN0l02_s?si=x0enTSJVqedu5NN0"
              target="_blank"
            >
              <span
                className="d-flex align-items-center  btn-md guide_btn"
                style={{
                  right: "0.5rem",
                  backgroundColor: "#003441",
                  color: "white",
                  borderRadius: "4px",
                  padding: "4px 13px",
                  fontSize: "12px",
                }}
              >
                Youtube
                <img
                  className="ml-2"
                  src={"/images/Homepage/youtube.png"}
                  alt=""
                  width="15px"
                />
              </span>
            </a>
          </div> */}

          {/* <div className='main_area'>
            <div
              className='p-4 bg-light'
              style={{ border: '1px solid #e0e0e0', borderRadius: '5px' }}
            >
              <Link
                className={`d-flex align-items-center ${css.profile_wallet} undefined`}
                to='/transaction-history'
              >
                <picture className='ml-4'>
                  <img
                    width='32px'
                    src={
                      process.env.PUBLIC_URL +
                      '/images/Header/order-history.png'
                    }
                    alt=''
                  />
                </picture>
                <div className={`ml-5 ${css.mytext} text-muted `}>
                  Order History
                </div>
              </Link>
            </div>
          </div> */}

          <div className="mt-2">
            <div className="wallet_page_cards">
              <div
                className="profile_headings"
                style={{ display: "flex", alignItems: "center", color:"White" }}
              >
              <img src="/images/icons/deposit.png" style={{height:"48px", width:"35px", marginRight:"10px"}}/>  Deposit Cash{" "}
                <span
                  className=" ml-2"
                  style={{ display: "flex", alignItems: "center" }}
                >
                <img src="./nkcoin.png"  style={{width:"22px", marginRight:"5px"}}/>
                  {user && user.Wallet_balance - user.withdrawAmount}
                </span>
              </div>
              <p className="">
                Can be used to play Tournaments &amp; Battles. Cannot be
                withdrawn to Paytm or Bank.
              </p>
              <Link className="block_end_text" to="/addcase">
                <button className="samll_btn">
                  <AiOutlinePlus className="icons" /> {translations[language].amount}
                </button>
              </Link>
            </div>
            
    <div className="wallet_page_cards">
      <div
        className="profile_headings"
        style={{ display: "flex", alignItems: "center", color: "white" }}
      >
        <img 
          src="/images/icons/money-withdraw.png" 
          alt="Money Withdraw Icon" 
          style={{ height: "48px", width: "35px", marginRight: "10px" }}
        />
        Winning Cash
        <span
          className="ml-2"
          style={{ display: "flex", alignItems: "center" }}
        >
          <img 
            src="./nkcoin.png" 
            alt="Coin Icon" 
            style={{ width: "22px", marginRight: "5px" }} 
          />
          {user && user.withdrawAmount}
        </span>
      </div>

      <p>
        Can be withdrawn to Paytm or Bank. Can be used to play Tournaments &amp; Battles.
      </p>
      {
  (parseInt(profileData.lastWitdrawl) + parseInt(addCaseSetting?.withdrawTime) < time || 
  profileData.lastWitdrawl == null || transaction == "FAILED" || transaction == "REJECT") ? (
      <Link className="block_end_text" to="/Withdrawopt">
        <button className="samll_btn">
          <BsWallet2 className="icons" /> {translations[language].withdraw}
        </button>
      </Link>
  ) : (
    <Link className="block_end_text" to="/wallet">
        <button className="samll_btn" onClick={sweetAlert}>
          <BsWallet2 className="icons" /> {translations[language].withdraw}
        </button>
      </Link>
  )
}
    </div>

          </div>
        </div>
        <div className="rightContainer">
          <div>
            <Rightcontainer />
          </div>
        </div>
      </div>
    </>
  );
};
export default Mywallet;
